import axios from 'axios';
import authAxios from '@/utils/authAxios';

export default {
  getPsychologists: () => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(process.env.VUE_APP_BACKEND_URL + '/psychologist')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  /**
   ********************************************************************************************************
   */
  getDoctor: doctor => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_BACKEND_URL + '/doctor/' + doctor)
        .then(response => {
          if (response.data.doctor.avatar_url) {
            response.data.doctor.avatar_url =
              process.env.VUE_APP_BACKEND_URL_ABS +
              response.data.doctor.avatar_url;
          }
          if (response.data.doctor.logo_url) {
            response.data.doctor.logo_url =
              process.env.VUE_APP_BACKEND_URL_ABS +
              response.data.doctor.logo_url;
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getSchedule: ({ id, month, year }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_BACKEND_URL + '/schedule', {
          id,
          month,
          year,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getPatientsAdmin: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/doctor_data/patients', {
          id,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateDoctor: ({
    rate,
    specialty,
    address,
    description,
    prices,
    linkedin,
    logo,
    avatar,
  }) => {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      form.append('rate', rate);
      form.append('specialty', specialty);
      form.append('address', address);
      form.append('description', description);
      form.append('prices', prices);
      form.append('linkedin', linkedin);
      form.append('logo', logo);
      form.append('avatar', avatar);

      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/user/doctor', form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateDoctorAdmin: (
    id,
    {
      rate,
      name,
      specialty,
      address,
      description,
      prices,
      linkedin,
      logo,
      avatar,
    }
  ) => {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      form.append('id', id);
      form.append('rate', rate);
      form.append('name', name);
      form.append('specialty', specialty);
      form.append('address', address);
      form.append('description', description);
      form.append('prices', prices);
      form.append('linkedin', linkedin);
      form.append('logo', logo);
      form.append('avatar', avatar);

      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/user/doctor_admin', form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  schedule: ({ doctor, date }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_BACKEND_URL + '/doctorschedule', {
          doctor,
          date,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  noteCreate: ({ results, id }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/note_create', {
          id,
          results,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  noteCreateAdmin: (doctor, { results, id }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/note_create_admin', {
          id,
          results,
          doctor,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  noteDelete: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .delete(process.env.VUE_APP_BACKEND_URL + '/note_delete/' + id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  noteDeleteAdmin: (doctor, id) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/note_delete_admin', {
          id,
          doctor,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  meetingCreate: ({ url, id }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/meeting_create', {
          id,
          url,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  meetingCreateAdmin: (doctor, { url, id }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/meeting_create_admin', {
          id,
          url,
          doctor,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  meetingDelete: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .delete(process.env.VUE_APP_BACKEND_URL + '/meeting_delete/' + id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  meetingDeleteAdmin: (doctor, id) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/meeting_delete_admin', {
          id,
          doctor,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
