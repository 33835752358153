<template>
  <div class="w-full">
    <modal-register-patient
      :trigger="registerPatientToogle"
      @toggle="
        event => {
          registerPatientToogle = null;
          event == 'save' ? getPatients() : '';
        }
      "
    />
    <modal-register-doctor
      :trigger="registerDoctorToogle"
      @toggle="
        event => {
          registerDoctorToogle = null;
          event == 'save' ? getPatients() : '';
        }
      "
    />
    <modal-register-superdoctor
      :trigger="registerSuperdoctorToogle"
      @toggle="
        event => {
          registerSuperdoctorToogle = null;
          event == 'save' ? getPatients() : '';
        }
      "
    />
    <modal-register-psychologist
      :trigger="registerPsychologistToogle"
      @toggle="
        event => {
          registerPsychologistToogle = null;
          event == 'save' ? getPatients() : '';
        }
      "
    />
    <modal-register-admin
      :trigger="registerAdminToogle"
      @toggle="
        event => {
          registerAdminToogle = null;
          event == 'save' ? getPatients() : '';
        }
      "
    />
    <modal-delete-user
      :trigger="deleteToogle"
      @toggle="
        event => {
          deleteToogle = null;
          event == 'save' ? getPatients() : '';
        }
      "
    />
    <div class="mb-4 flex flex-row justify-end items-center w-full">
      <create-patient @then="then" />
    </div>
    <input-search v-model="search" />
    <table-component
      :loading="loading"
      :head="[
        { key: 'patient', label: 'Paciente' },
        { key: 'identity', label: 'Datos' },
        { key: 'actions', label: 'Acciones' },
      ]"
      :body="body"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PatientService from '@/services/patientService';
import RegisterDoctor from '@/views/dashboard/user/admin/modals/registerDoctor.vue';
import RegisterPsychologist from '@/views/dashboard/user/admin/modals/registerPsychologist.vue';
import RegisterSuperdoctor from '@/views/dashboard/user/admin/modals/registerSuperdoctor.vue';
import RegisterPatient from '@/views/dashboard/user/admin/modals/editPatient.vue';
import RegisterAdmin from '@/views/dashboard/user/admin/modals/registerAdmin.vue';
import DeleteUser from '@/views/dashboard/user/admin/modals/deleteUser.vue';

import CreatePatient from '@/views/dashboard/user/admin/create/patient/Button.vue';

import InputSearch from '@/components/form/InputSearch.vue';
import Table from '@/components/dashboard/user/admin/Table.vue';

import moment from 'moment';

export default {
  name: 'Patients',
  components: {
    'modal-register-patient': RegisterPatient,
    'modal-register-doctor': RegisterDoctor,
    'modal-register-superdoctor': RegisterSuperdoctor,
    'modal-register-psychologist': RegisterPsychologist,
    'modal-register-admin': RegisterAdmin,
    'modal-delete-user': DeleteUser,
    'create-patient': CreatePatient,
    'input-search': InputSearch,
    'table-component': Table,
  },
  data() {
    return {
      search: '',
      patients: [],
      registerDoctorToogle: null,
      registerSuperdoctorToogle: null,
      registerAdminToogle: null,
      deleteToogle: null,
      registerPatientToogle: null,
      registerPsychologistToogle: null,
      loading: true,
    };
  },
  created() {
    this.getPatients();
  },
  methods: {
    then(patient) {
      if (patient) {
        this.getPatients();
      }
    },
    getPatients() {
      PatientService.getPatients()
        .then(response => {
          this.patients = response.data.patients;
          this.loading = false;
        })
        .catch(() => {
          console.log('error getting patients');
        });
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
    body() {
      let temp = this.patients;
      if (this.search != '') {
        temp = temp.filter(e => {
          let name_temp = e.names + ' ' + e.lastnames;
          return name_temp.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      temp = temp.sort(function(a, b) {
        if (a.id > b.id) {
          return -1;
        }
        if (a.id < b.id) {
          return 1;
        }
        return 0;
      });
      return temp.map(e => {
        return {
          patient: [
            { label: e.names + ' ' + e.lastnames, class: 'capitalize' },
            { label: e.email, class: 'text-gray-600 lowercase' },
            {
              label: 'contacto: ' + e.patient.contact,
              class: 'text-gray-600 lowercase',
            },
          ],
          identity: [
            { label: e.patient.phone },
            { label: e.patient.rut },
            {
              label: 'fecha nacimiento',
              class: 'text-gray-600 lowercase',
            },
            {
              label: moment(e.patient.birthdate).format('DD/MM/YYYY'),
              class: 'text-gray-600 lowercase',
            },
            {
              label: 'Previsión salud',
              class: 'text-gray-600 lowercase',
            },
            {
              label: e.patient.health ? e.patient.health : '-',
              class: 'text-gray-600 lowercase',
            },
          ],
          actions: [
            {
              label: 'convertir admin',
              dropdown: true,
              class:
                'bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-md mb-2 w-full',
              action: () => {
                this.registerAdminToogle = e.id;
              },
            },
            {
              label: 'convertir a doctor',
              dropdown: true,
              class:
                'bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-md mb-2 w-full',
              action: () => {
                this.registerDoctorToogle = e.id;
              },
            },
            {
              label: 'convertir a super doctor',
              dropdown: true,
              class:
                'bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-md mb-2 w-full',
              action: () => {
                this.registerSuperdoctorToogle = e.id;
              },
            },
            {
              label: 'convertir a psicólogo',
              dropdown: true,
              class:
                'bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-md mb-2 w-full',
              action: () => {
                this.registerPsychologistToogle = e.id;
              },
            },
            {
              label: 'eliminar',
              dropdown: true,
              class:
                'bg-red-100 hover:bg-red-200 px-2 py-1 rounded-md mb-2 w-full',
              action: () => {
                this.deleteToogle = e.id;
              },
            },
            {
              label: 'ver datos',
              class:
                'bg-green-700 hover:bg-green-800 text-gray-100 px-2 py-1 rounded-md mb-2 w-full',
              action: () => {
                this.registerPatientToogle = e.id;
              },
            },
          ],
        };
      });
    },
  },
};
</script>
