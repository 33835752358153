var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('modal-register-patient',{attrs:{"trigger":_vm.registerPatientToogle},on:{"toggle":function (event) {
        _vm.registerPatientToogle = null;
        event == 'save' ? _vm.getAdmin() : '';
      }}}),_c('modal-register-doctor',{attrs:{"trigger":_vm.registerDoctorToogle},on:{"toggle":function (event) {
        _vm.registerDoctorToogle = null;
        event == 'save' ? _vm.getAdmin() : '';
      }}}),_c('modal-register-superdoctor',{attrs:{"trigger":_vm.registerSuperdoctorToogle},on:{"toggle":function (event) {
        _vm.registerSuperdoctorToogle = null;
        event == 'save' ? _vm.getAdmin() : '';
      }}}),_c('modal-delete-user',{attrs:{"trigger":_vm.deleteToogle},on:{"toggle":function (event) {
        _vm.deleteToogle = null;
        event == 'save' ? _vm.getAdmin() : '';
      }}}),_c('modal-register-admin',{attrs:{"trigger":_vm.registerAdminToogle},on:{"toggle":function (event) {
        _vm.registerAdminToogle = null;
        event == 'save' ? _vm.getAdmin() : '';
      }}}),_c('modal-register-psychologist',{attrs:{"trigger":_vm.registerPsychologistToogle},on:{"toggle":function (event) {
        _vm.registerPsychologistToogle = null;
        event == 'save' ? _vm.getAdmin() : '';
      }}}),_c('div',{staticClass:"mb-4 flex flex-row justify-end items-center w-full"},[_c('create-admin',{on:{"then":_vm.then}})],1),_c('input-search',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('table-component',{attrs:{"loading":_vm.loading,"head":[
      { key: 'user', label: 'Usuario' },
      { key: 'actions', label: 'Acciones' } ],"body":_vm.body}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }