<template>
  <div class="w-full">
    <are-you-sure
      :trigger="sure"
      @toggle="
        event => {
          switch (event) {
            case 'save':
              deleteUser(sure);
              break;
            default:
              sure = null;
              finish('ok');
              break;
          }
        }
      "
    />
  </div>
</template>

<script>
import UserService from '@/services/userService';
import AreYouSure from '@/components/modals/AreYouSure.vue';

function initialState(trigger) {
  return {
    press: true,
    sure: trigger,
  };
}

export default {
  name: 'DeleteUser',
  props: {
    trigger: {
      type: Number,
      default: null,
    },
  },
  components: {
    'are-you-sure': AreYouSure,
  },
  data() {
    return initialState(this.trigger);
  },
  watch: {
    trigger() {
      this.sure = this.trigger;
    },
  },
  methods: {
    finish(status) {
      this.$emit('toggle', status);
      Object.assign(this.$data, initialState());
    },
    deleteUser(id) {
      if (this.press) {
        this.press = false;
        UserService.deleteUser(id)
          .then(() => {
            this.press = true;
            this.finish('save');
          })
          .catch(() => {
            console.log('error deleting user');
          });
      }
    },
  },
};
</script>
