<template>
  <div class="flex flex-col justify-start items-start min-height">
    <span class="text-xl text-gray-900 font-semibold"
      >Gestión <small>[{{ body.length }}]</small></span
    >
    <div class="w-full mt-2">
      <input-search v-model="search" />
      <table-component
        class="w-full mt-4"
        :loading="loading"
        :head="[
          { key: 'lastnames', label: 'Apellidos Profesional' },
          { key: 'names', label: 'Nombres Profesional' },
          { key: 'specialty', label: 'Especialidad' },
          { key: 'income', label: 'Ingresos' },
          { key: 'total', label: 'Total Reservas' },
          { key: 'completed', label: 'Reservas Completadas' },
          { key: 'incompleted', label: 'Reservas No Completadas' },
          { key: 'percentage_complete', label: '% Reservas Completadas' },
          { key: 'percentage_incomplete', label: '% Reservas No Completadas' },
          { key: 'license', label: 'Número de Licencias' },
          { key: 'percentage_license', label: '% de Licencias' },
          { key: 'percentage_approval', label: '% de Aprobación' },
        ]"
        :body="body"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import AdminService from '@/services/adminService';
import InputSearch from '@/components/form/InputSearch.vue';
import Table from '@/components/dashboard/user/admin/Table.vue';

export default {
  name: 'Management',
  components: {
    'input-search': InputSearch,
    'table-component': Table,
  },
  data() {
    return {
      loading: true,
      search: '',
      management: null,
    };
  },
  created() {
    AdminService.getManagement()
      .then(response => {
        this.management = response.data.management;
        this.loading = false;
      })
      .catch(() => {
        this.$Error.fire({
          title: 'Error al obtener la Gestión',
        });
      });
  },
  methods: {
    formatDateNative(date) {
      moment.locale('es');
      let date_temp = moment(date, 'YYYY-MM-DD HH:mm');
      date_temp.locale(false);
      return date_temp.format('L');
    },
    formatDateNativeHour(date) {
      moment.locale('es');
      let date_temp = moment(date, 'YYYY-MM-DD HH:mm');
      date_temp.locale(false);
      return date_temp.format('HH:mm A');
    },
    chileanPesos(value) {
      let chileanPeso = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
      return chileanPeso.format(value).replace(/,/g, '.');
    },
    addMonth(date) {
      const [day, month, year] = date.split('/');
      return `${day}/${parseInt(month) + 1}/${year}`;
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
    body() {
      let temp = this.management ? this.management : [];
      if (this.search != '') {
        temp = temp.filter(e => {
          let name_temp = `${e.lastnames} ${e.names}`;
          return name_temp.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      return temp.map(e => {
        return {
          lastnames: [
            {
              label: e.lastnames,
              class:
                'capitalize text-green-600 hover:text-green-700 underline text-sm cursor-pointer',
              action: () => {
                this.$router.push({
                  path: `/${
                    this.user.roles.find(e => e.name == 'superdoctor')
                      ? 'superdoctor'
                      : 'admin'
                  }/profesional/${e.id}`,
                });
              },
            },
          ],
          names: [
            {
              label: e.names,
              class:
                'capitalize text-green-600 hover:text-green-700 underline text-sm cursor-pointer',
              action: () => {
                this.$router.push({
                  path: `/${
                    this.user.roles.find(e => e.name == 'superdoctor')
                      ? 'superdoctor'
                      : 'admin'
                  }/profesional/${e.id}`,
                });
              },
            },
          ],
          specialty: {
            label: e.specialty,
            class:
              'w-auto text-green-900 bg-green-100 rounded-md px-2 mt-1 lowercase',
          },
          income: {
            label: this.chileanPesos(e.income),
            class: 'capitalize',
          },
          total: {
            label: e.total,
          },
          completed: {
            label: e.completed,
          },
          incompleted: {
            label: e.incompleted,
          },
          percentage_complete: {
            label: `${e.percentage_complete}%`,
          },
          percentage_incomplete: {
            label: `${e.percentage_incomplete}%`,
          },
          license: {
            label: e.license,
          },
          percentage_license: {
            label: `${e.percentage_license}%`,
          },
          percentage_approval: {
            label: `${e.percentage_approval}%`,
          },
        };
      });
    },
  },
};
</script>

<style scoped>
.min-height {
  min-height: 80vh;
}
</style>
