<template>
  <div>
    <modal v-if="toggle" v-model="toggle" @then="then" />
    <button
      type="button"
      @click="
        () => {
          toggle = true;
        }
      "
      class="flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-auto sm:text-sm"
    >
      Crear un doctor
    </button>
  </div>
</template>

<script>
import CreateDoctorModal from '@/views/dashboard/user/admin/create/doctor/Modal.vue';

export default {
  name: 'CreateUserButton',
  components: {
    modal: CreateDoctorModal,
  },
  data() {
    return {
      toggle: false,
    };
  },
  methods: {
    then(doctor) {
      this.toggle = false;
      this.$emit('then', doctor);
    },
  },
};
</script>
