var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col justify-start items-start min-height"},[_c('span',{staticClass:"text-xl text-gray-900 font-semibold"},[_vm._v("Gestión "),_c('small',[_vm._v("["+_vm._s(_vm.body.length)+"]")])]),_c('div',{staticClass:"w-full mt-2"},[_c('input-search',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('table-component',{staticClass:"w-full mt-4",attrs:{"loading":_vm.loading,"head":[
        { key: 'lastnames', label: 'Apellidos Profesional' },
        { key: 'names', label: 'Nombres Profesional' },
        { key: 'specialty', label: 'Especialidad' },
        { key: 'income', label: 'Ingresos' },
        { key: 'total', label: 'Total Reservas' },
        { key: 'completed', label: 'Reservas Completadas' },
        { key: 'incompleted', label: 'Reservas No Completadas' },
        { key: 'percentage_complete', label: '% Reservas Completadas' },
        { key: 'percentage_incomplete', label: '% Reservas No Completadas' },
        { key: 'license', label: 'Número de Licencias' },
        { key: 'percentage_license', label: '% de Licencias' },
        { key: 'percentage_approval', label: '% de Aprobación' } ],"body":_vm.body}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }