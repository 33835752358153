<template>
  <div class="w-full">
    <modal-register-patient
      :trigger="registerPatientToogle"
      @toggle="
        event => {
          registerPatientToogle = null;
          event == 'save' ? getAdmin() : '';
        }
      "
    />
    <modal-register-doctor
      :trigger="registerDoctorToogle"
      @toggle="
        event => {
          registerDoctorToogle = null;
          event == 'save' ? getAdmin() : '';
        }
      "
    />
    <modal-register-superdoctor
      :trigger="registerSuperdoctorToogle"
      @toggle="
        event => {
          registerSuperdoctorToogle = null;
          event == 'save' ? getAdmin() : '';
        }
      "
    />
    <modal-delete-user
      :trigger="deleteToogle"
      @toggle="
        event => {
          deleteToogle = null;
          event == 'save' ? getAdmin() : '';
        }
      "
    />
    <modal-register-admin
      :trigger="registerAdminToogle"
      @toggle="
        event => {
          registerAdminToogle = null;
          event == 'save' ? getAdmin() : '';
        }
      "
    />
    <modal-register-psychologist
      :trigger="registerPsychologistToogle"
      @toggle="
        event => {
          registerPsychologistToogle = null;
          event == 'save' ? getAdmin() : '';
        }
      "
    />
    <div class="mb-4 flex flex-row justify-end items-center w-full">
      <create-admin @then="then" />
    </div>
    <input-search v-model="search" />
    <table-component
      :loading="loading"
      :head="[
        { key: 'user', label: 'Usuario' },
        { key: 'actions', label: 'Acciones' },
      ]"
      :body="body"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AdminService from '@/services/adminService';
import RegisterDoctor from '@/views/dashboard/user/admin/modals/registerDoctor.vue';
import RegisterSuperdoctor from '@/views/dashboard/user/admin/modals/registerSuperdoctor.vue';
import RegisterPatient from '@/views/dashboard/user/admin/modals/editPatient.vue';
import RegisterPsychologist from '@/views/dashboard/user/admin/modals/registerPsychologist.vue';
import RegisterAdmin from '@/views/dashboard/user/admin/modals/registerAdmin.vue';
import DeleteUser from '@/views/dashboard/user/admin/modals/deleteUser.vue';

import CreateAdmin from '@/views/dashboard/user/admin/create/admin/Button.vue';

import InputSearch from '@/components/form/InputSearch.vue';
import Table from '@/components/dashboard/user/admin/Table.vue';

export default {
  name: 'Admins',
  components: {
    'modal-register-patient': RegisterPatient,
    'modal-register-doctor': RegisterDoctor,
    'modal-register-superdoctor': RegisterSuperdoctor,
    'modal-register-admin': RegisterAdmin,
    'modal-register-psychologist': RegisterPsychologist,
    'modal-delete-user': DeleteUser,
    'create-admin': CreateAdmin,
    'input-search': InputSearch,
    'table-component': Table,
  },
  data() {
    return {
      search: '',
      users: [],
      registerDoctorToogle: null,
      registerSuperdoctorToogle: null,
      registerAdminToogle: null,
      registerPatientToogle: null,
      deleteToogle: null,
      registerPsychologistToogle: null,
      loading: true,
    };
  },
  created() {
    this.getAdmin();
  },
  methods: {
    then(admin) {
      if (admin) {
        this.getAdmin();
      }
    },
    getAdmin() {
      AdminService.getAdmins()
        .then(response => {
          this.users = response.data.admins;
          this.loading = false;
        })
        .catch(() => {
          console.log('error getting admins');
        });
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
    body() {
      let temp = this.users;
      if (this.search != '') {
        temp = temp.filter(e => {
          let name_temp = e.names + ' ' + e.lastnames;
          return name_temp.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      temp = temp.sort(function(a, b) {
        if (a.id > b.id) {
          return -1;
        }
        if (a.id < b.id) {
          return 1;
        }
        return 0;
      });
      return temp.map(e => {
        return {
          user: [
            { label: e.names + ' ' + e.lastnames, class: 'capitalize' },
            { label: e.email, class: 'text-gray-600 lowercase' },
          ],
          actions: [
            {
              label: 'convertir a paciente',
              dropdown: true,
              class:
                'bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-md mb-2 w-full',
              action: () => {
                this.registerPatientToogle = e.id;
              },
            },
            {
              label: 'convertir a doctor',
              dropdown: true,
              class:
                'bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-md mb-2 w-full',
              action: () => {
                this.registerDoctorToogle = e.id;
              },
            },
            {
              label: 'convertir a super doctor',
              dropdown: true,
              class:
                'bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-md mb-2 w-full',
              action: () => {
                this.registerSuperdoctorToogle = e.id;
              },
            },
            {
              label: 'convertir a psicólogo',
              dropdown: true,
              class:
                'bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-md mb-2 w-full',
              action: () => {
                this.registerPsychologistToogle = e.id;
              },
            },
            {
              label: 'eliminar',
              dropdown: true,
              class:
                'bg-red-100 hover:bg-red-200 px-2 py-1 rounded-md mb-2 w-full',
              action: () => {
                this.deleteToogle = e.id;
              },
            },
          ],
        };
      });
    },
  },
};
</script>
