<template>
  <div class="pt-4 flex flex-col w-full justify-start items-start">
    <div
      class="w-full border border-gray-200 py-4 px-4 text-gray-900 flex flex-col justify-start items-start rounded-md bg-white"
    >
      <div
        class="border rounded-md border-gray-200 w-full flex justify-between items-center p-2 mb-4"
      >
        <svg
          v-if="user"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
          />
        </svg>

        <span v-if="user" class="text-gray-700 text-sm font-bold"
          >Perfil Seleccionado</span
        >
        <span v-else class="text-gray-700 text-sm font-bold"
          >Perfil No Seleccionado</span
        >
      </div>
      <div v-if="user" class="w-full flex flex-col justify-center items-start">
        <div class="mb-3">
          <div
            class="text-sm w-full capitalize text-left font-medium text-gray-900"
          >
            {{ user.names }}
            {{ user.lastnames }}
          </div>
          <div class="text-sm w-full text-left text-gray-500">
            {{ user.email }}
          </div>
          <div
            v-if="user.doctor"
            class="text-sm w-full text-left text-gray-900"
          >
            Doctor
          </div>
          <div
            v-if="user.psychologist"
            class="text-sm w-full text-left text-gray900"
          >
            Psicólogo
          </div>
        </div>
        <button
          type="button"
          @click="unselectUser()"
          class="w-full bg-red-100 border border-red-200 hover:bg-red-200 text-red-900 font-bold px-2 py-1 rounded-md text-sm font-medium"
        >
          deseleccionar
        </button>
      </div>
      <div v-else class="animate-pulse w-1/3 flex space-x-4">
        <div class="flex-1 space-y-6 py-1">
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-gray-500 rounded col-span-2"></div>
            </div>
            <div class="h-2 bg-gray-500 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'UserCard',
  data() {
    return {};
  },
  methods: {
    ...mapActions('admin', ['changeUser']),
    unselectUser() {
      this.changeUser(null);
    },
  },
  computed: {
    ...mapGetters({
      user: ['admin/user'],
    }),
  },
};
</script>
