<template>
  <div class="flex flex-col justify-start items-start min-height">
    <span class="text-xl text-gray-900 font-semibold"
      >Prestaciones <small>[{{ body.length }}]</small></span
    >
    <div class="w-full mt-2">
      <div
        class="w-full grid md:gap-x-10 grid-cols-1 md:grid-cols-12 items-end"
      >
        <div
          class="col-span-5 w-full flex flex-col justify-start items-start mb-2"
        >
          <span class="text-sm text-gray-800 font-bold">Desde</span>
          <date-picker :default="start_date" @update="updateStartDate" />
        </div>
        <div
          class="col-span-5 w-full flex flex-col justify-start items-start mb-2"
        >
          <span class="text-sm text-gray-800 font-bold">Hasta</span>
          <date-picker :default="end_date" @update="updateEndDate" />
        </div>
      </div>
      <input-search v-model="search" />
      <table-component
        class="w-full mt-4"
        :loading="loading"
        :head="[
          { key: 'code', label: 'Código' },
          { key: 'name', label: 'Nombres' },
          { key: 'reservation', label: 'Reserva' },
          { key: 'status', label: 'Estado Bono' },
          { key: 'bond', label: 'Gestionar' },
          { key: 'form', label: 'Ficha Primera Consulta' },
        ]"
        :body="body"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import AdminService from '@/services/adminService';
import InputSearch from '@/components/form/InputSearch.vue';
import Table from '@/components/dashboard/user/admin/Table.vue';
import Datepicker from '@/components/form/Datepicker.vue';

export default {
  name: 'Bonds',
  components: {
    'input-search': InputSearch,
    'table-component': Table,
    'date-picker': Datepicker,
  },
  data() {
    return {
      loading: true,
      search: '',
      bonds: null,
      start_date: `1/${moment().month()}/${moment().year()}`,
      end_date: `${moment().daysInMonth()}/${moment().month()}/${moment().year()}`,
    };
  },
  created() {
    AdminService.getBonds()
      .then(response => {
        this.bonds = response.data.bonds;
        this.loading = false;
      })
      .catch(() => {
        this.$Error.fire({
          title: 'Error al obtener las reservas',
        });
      });
  },
  methods: {
    updateStartDate(date) {
      this.start_date = date;
    },
    updateEndDate(date) {
      this.end_date = date;
    },
    formatDateNative(date) {
      moment.locale('es');
      let date_temp = moment(date, 'YYYY-MM-DD HH:mm');
      date_temp.locale(false);
      return date_temp.format('L');
    },
    formatDateNativeHour(date) {
      moment.locale('es');
      let date_temp = moment(date, 'YYYY-MM-DD HH:mm');
      date_temp.locale(false);
      return date_temp.format('HH:mm A');
    },
    chileanPesos(value) {
      let chileanPeso = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
      return chileanPeso.format(value).replace(/,/g, '.');
    },
    addMonth(date) {
      const [day, month, year] = date.split('/');
      return `${day}/${parseInt(month) + 1}/${year}`;
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
    body() {
      let temp = this.bonds ? this.bonds : [];
      if (this.search != '') {
        temp = temp.filter(e => {
          let name_temp = e.patient;
          return name_temp.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      temp = temp.filter(e => {
        return moment(e.date, 'YYYY-MM-DD HH:mm').isBetween(
          moment(this.addMonth(this.start_date), 'DD/MM/YYYY'),
          moment(this.addMonth(this.end_date), 'DD/MM/YYYY')
        );
      });
      temp = temp.sort(function(a, b) {
        if (a.id > b.id) {
          return -1;
        }
        if (a.id < b.id) {
          return 1;
        }
        return 0;
      });
      return temp.map(e => {
        return {
          code: [
            {
              label: e.code,
              class: 'uppercase',
            },
          ],
          name: [
            {
              label: 'Nombre Paciente',
              class: 'capitalize font-bold',
            },
            {
              label: e.patient,
              class: 'capitalize mb-2',
            },
            {
              label: 'Nombre Profesional',
              class: 'capitalize font-bold',
            },
            {
              label: e.name,
              class: 'capitalize',
            },
            {
              label: e.specialty,
              class:
                'w-auto text-green-900 bg-green-100 rounded-md px-2 mt-1 lowercase',
            },
          ],
          reservation: [
            {
              label: 'Fecha Reserva',
              class: 'capitalize font-bold',
            },
            {
              label: this.formatDateNative(e.date),
            },
            {
              label: this.formatDateNativeHour(e.date),
              class: 'mb-2',
            },
            {
              label: 'Tarifa Prestación',
              class: 'capitalize font-bold',
            },
            {
              label: this.chileanPesos(e.amount),
            },
          ],
          status: [
            {
              label: e.status,
              class:
                e.status === 'Aceptado'
                  ? 'text-green-900'
                  : e.status === 'Rechazado'
                  ? 'text-red-900'
                  : e.status === 'Pendiente'
                  ? 'text-yellow-900'
                  : 'text-blue-900',
            },
          ],
          form: [
            {
              label: e.form ? 'SÍ' : 'NO',
            },
          ],
          bond: [
            {
              label: 'Gestionar Reserva',
              class: 'text-green-600 hover:text-green-700 underline text-sm',
              action: () => {
                this.$router.push({
                  path: `/${
                    this.user.roles.find(e => e.name == 'superdoctor')
                      ? 'superdoctor'
                      : 'admin'
                  }/reservation/${e.code}`,
                });
              },
            },
          ],
        };
      });
    },
  },
};
</script>

<style scoped>
.min-height {
  min-height: 80vh;
}
</style>
