<template>
  <modal-container v-if="value">
    <form
      @submit.prevent="handleSubmit"
      v-click-outside="pressOutside"
      class="inline-block align-middle transform transition-all sm:max-w-2xl w-full"
    >
      <div class="shadow-xl rounded-md">
        <div class="bg-white px-4 py-5 sm:p-6  rounded-t-md">
          <div class="grid grid-cols-6 gap-6">
            <!-- title -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Crear un paciente
              </h3>
              <p class="mt-1 text-left max-w-2xl text-sm text-gray-500">
                Completa el formulario para crear una cuenta asociada a un
                paciente.
              </p>
            </div>
            <!-- names -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label for="names" class="block text-sm font-medium text-gray-700"
                >Nombres</label
              >
              <div class="mt-1 w-full">
                <input
                  v-model="form.names"
                  type="text"
                  name="names"
                  id="names"
                  :class="
                    submitted && $v.form.names.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="Escribe aquí"
                />
              </div>
              <div
                v-if="submitted && !$v.form.names.required"
                class="text-sm text-red-600"
              >
                Los nombres son obligatorios
              </div>
            </div>
            <!-- lastnames -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label
                for="lastnames"
                class="block text-sm font-medium text-gray-700"
                >Apellidos</label
              >
              <div class="mt-1 w-full">
                <input
                  v-model="form.lastnames"
                  type="text"
                  name="lastnames"
                  id="lastnames"
                  :class="
                    submitted && $v.form.lastnames.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="Escribe aquí"
                />
              </div>
              <div
                v-if="submitted && !$v.form.lastnames.required"
                class="text-sm text-red-600"
              >
                Los apellidos son obligatorios
              </div>
            </div>
            <!-- email -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label for="email" class="block text-sm font-medium text-gray-700"
                >Correo eletrónico</label
              >
              <div class="mt-1 w-full">
                <input
                  v-model="form.email"
                  type="text"
                  name="email"
                  id="email"
                  :class="
                    submitted && $v.form.email.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="xxxxx@xxxxx.xxx"
                />
              </div>
              <div
                v-if="submitted && !$v.form.email.required"
                class="text-sm text-red-600"
              >
                La dirección de correo es obligatoria
              </div>
              <div
                v-if="submitted && !$v.form.email.email"
                class="text-sm text-red-600"
              >
                La dirección de correo no es válida
              </div>
            </div>
            <!-- email_confirmation -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label
                for="email_confirmation"
                class="block text-sm font-medium text-gray-700"
                >Confirma el correo electrónico</label
              >
              <div class="mt-1 w-full">
                <input
                  v-model="form.email_confirmation"
                  type="text"
                  name="email_confirmation"
                  id="email_confirmation"
                  :class="
                    submitted && $v.form.email_confirmation.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="xxxxx@xxxxx.xxx"
                />
              </div>
              <div
                v-if="submitted && !$v.form.email_confirmation.required"
                class="text-sm text-red-600"
              >
                La dirección de correo es obligatoria
              </div>
              <div
                v-if="submitted && !$v.form.email_confirmation.email"
                class="text-sm text-red-600"
              >
                La dirección de correo no es válida
              </div>
              <div
                v-if="submitted && !$v.form.email_confirmation.sameAsEmail"
                class="text-sm text-red-600"
              >
                Las direcciones de correo no coinciden
              </div>
            </div>
            <!-- divider -->
            <div class="col-span-6 border-t border-gray-100 mt-2"></div>
            <!-- ROLE USER DATA -->
            <!-- rut -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label for="rut" class="block text-sm font-medium text-gray-700"
                >Rut</label
              >
              <div class="mt-1 w-full">
                <input
                  v-model="form.rut"
                  type="text"
                  name="rut"
                  id="rut"
                  :class="
                    submitted && $v.form.rut.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="11.111.111-1"
                />
              </div>
              <div
                v-if="submitted && !$v.form.rut.required"
                class="text-sm text-red-600"
              >
                El rut es obligatorio
              </div>
              <div
                v-if="submitted && !$v.form.rut.rut"
                class="text-sm text-red-600"
              >
                El rut no es válido
              </div>
            </div>
            <!-- birthdate -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label
                for="birthdate"
                class="block text-sm font-medium text-gray-700"
              >
                Fecha de nacimiento
                <span class="font-xs font-light mr-2" v-if="age != null"
                  >/</span
                >
                <span class="font-bold" v-if="age != null">{{ age }}</span>
              </label>
              <div class="mt-1 w-full grid grid-cols-1 md:grid-cols-3 gap-2">
                <select
                  v-model="form.year"
                  :class="
                    submitted && $v.form.birthdate.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                >
                  <option selected disabled :value="''">Año</option>
                  <option
                    v-for="(year, yearIndex) in years_list()"
                    :key="yearIndex"
                    >{{ year }}</option
                  >
                </select>
                <select
                  v-model="form.month"
                  :class="
                    submitted && $v.form.birthdate.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                >
                  <option selected disabled :value="''">Mes</option>
                  <option
                    v-for="(month, monthIndex) in months"
                    :key="monthIndex"
                    :value="monthIndex + 1"
                    >{{ month }}</option
                  >
                </select>
                <select
                  v-model="form.day"
                  :class="
                    submitted && $v.form.birthdate.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                >
                  <option selected disabled :value="''">Día</option>
                  <option
                    v-for="(day, dayIndex) in days_list"
                    :key="dayIndex"
                    >{{ day }}</option
                  >
                </select>
              </div>
              <div
                v-if="submitted && !$v.form.birthdate.required"
                class="text-sm text-red-600"
              >
                La fecha de nacimiento es obligatoria
              </div>
            </div>
            <!-- address -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <label
                class="w-full text-left block text-sm text-left font-medium text-gray-700"
              >
                Dirección de factura
              </label>
              <div class="mt-1 w-full grid gap-x-2 grid-cols-1 md:grid-cols-2">
                <select
                  v-model="form.region"
                  name="region"
                  :class="
                    submitted && $v.form.birthdate.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                >
                  <option selected disabled :value="''">Region</option>
                  <option
                    v-for="(region, regionIndex) in regiones"
                    :key="regionIndex"
                    >{{ region.region }}</option
                  >
                </select>
                <select
                  name="comuna"
                  autocomplete="comuna"
                  v-model="form.comuna"
                  :class="
                    submitted && $v.form.birthdate.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="col-span-2 md:col-span-1 w-full disabled:text-gray-400 border bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                >
                  <option selected disabled :value="''">Comuna</option>
                  <option
                    v-for="(comuna, comunaIndex) in comunas"
                    :key="comunaIndex"
                    >{{ comuna }}</option
                  >
                </select>
                <input
                  v-model="form.address"
                  name="address"
                  type="text"
                  :class="
                    submitted && $v.form.birthdate.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="col-span-2 mt-2 appearance-none relative block w-full px-3 py-2 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                  placeholder="Dirección"
                />
              </div>
              <div
                v-if="submitted && !$v.form.region.required"
                class="text-sm text-red-600"
              >
                La region es obligatoria
              </div>
              <div
                v-if="submitted && !$v.form.comuna.required"
                class="text-sm text-red-600"
              >
                La comuna es obligatoria
              </div>
              <div
                v-if="submitted && !$v.form.address.required"
                class="text-sm text-red-600"
              >
                La dirección es obligatoria
              </div>
            </div>
            <!-- prevision -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <label
                class="w-full text-left block text-sm text-left font-medium text-gray-700"
              >
                Previsión de salud
              </label>
              <div class="mt-1 w-full ">
                <select
                  name="prevision"
                  autocomplete="prevision"
                  v-model="form.prevision"
                  :class="
                    submitted && $v.form.prevision.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="col-span-6 mb-2 block w-full py-2 px-3 disabled:text-gray-400 border bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                >
                  <option selected disabled :value="null">Seleccionar</option>
                  <option
                    v-for="(prevision, previsionIndex) in previsions"
                    :key="previsionIndex"
                    >{{ prevision }}</option
                  >
                </select>
              </div>
              <div
                v-if="submitted && !$v.form.prevision.required"
                class="text-sm text-red-600"
              >
                La previsión de salud es obligatoria
              </div>
            </div>

            <!-- divider -->
            <div class="col-span-6 border-t border-gray-100 mt-2"></div>
            <!-- country -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label class="block text-left text-sm font-medium text-gray-700"
                >Código país de tu cuenta de Whatsapp</label
              >

              <div class="mt-1 w-full relative rounded-md shadow-sm">
                <button
                  @click="
                    () => {
                      countrySelector = !countrySelector;
                    }
                  "
                  v-click-outside="
                    () => {
                      countrySelector = false;
                    }
                  "
                  type="button"
                  class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm px-3 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                  aria-haspopup="listbox"
                  aria-expanded="true"
                  aria-labelledby="listbox-label"
                >
                  <span class="flex items-center">
                    <img
                      :src="country.flag"
                      alt=""
                      class="flex-shrink-0 h-5 w-5"
                    />
                    <span class="ml-3 block truncate">
                      ({{ country.dialCode }}) {{ country.name }}
                    </span>
                  </span>
                  <span
                    class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                  >
                    <!-- Heroicon name: solid/selector -->
                    <svg
                      class="h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </button>

                <transition
                  enter-active-class=""
                  enter-class=""
                  enter-to-class=""
                  leave-active-class="transition ease-in duration-100"
                  leave-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ul
                    v-if="countrySelector"
                    class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    tabindex="-1"
                    role="listbox"
                    aria-labelledby="listbox-label"
                    aria-activedescendant="listbox-option-3"
                  >
                    <li
                      v-for="(countryElement, countryIndex) in countries"
                      :key="countryIndex"
                      class="cursor-default select-none relative py-2 pl-3 pr-9"
                      :class="
                        countryElement.isoCode === country.isoCode
                          ? 'bg-gray-200'
                          : 'text-gray-900'
                      "
                      id="listbox-option-0"
                      role="option"
                      @click="
                        () => {
                          country = countryElement;
                        }
                      "
                    >
                      <div class="flex items-center">
                        <img
                          :src="countryElement.flag"
                          alt=""
                          class="flex-shrink-0 h-6 w-6"
                        />
                        <span
                          class="ml-3 block truncate"
                          :class="
                            countryElement.isoCode === country.isoCode
                              ? 'font-semibold'
                              : 'font-normal'
                          "
                        >
                          ({{ countryElement.dialCode }})
                          {{ countryElement.name }}
                        </span>
                      </div>

                      <span
                        class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4"
                        :class="
                          countryElement.isoCode === country.isoCode
                            ? 'text-green-500'
                            : 'hidden'
                        "
                      >
                        <!-- Heroicon name: solid/check -->
                        <svg
                          class="h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </li>

                    <!-- More items... -->
                  </ul>
                </transition>
              </div>
            </div>
            <!-- phone -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label for="phone" class="block text-sm font-medium text-gray-700"
                >Teléfono</label
              >
              <div class="mt-1 w-full flex rounded-md shadow-sm">
                <span
                  class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm"
                  >{{ country.dialCode }}</span
                >
                <input
                  type="text"
                  v-model="form.phone"
                  name="phone"
                  id="phone"
                  :class="
                    submitted && $v.form.phone.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block w-full min-w-0 flex-1 rounded-none rounded-r-md px-3 py-2 sm:text-sm"
                />
              </div>
              <div
                v-if="submitted && !$v.form.phone.required"
                class="text-sm text-red-600"
              >
                El teléfono es obligatorio
              </div>
            </div>

            <!-- divider -->
            <div class="col-span-6 border-t border-gray-100 mt-2"></div>
            <!-- password -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label
                for="password"
                class="block text-sm font-medium text-gray-700"
                >Contraseña</label
              >
              <div class="mt-1 w-full">
                <input
                  v-model="form.password"
                  type="password"
                  name="password"
                  id="password"
                  :class="
                    submitted && $v.form.password.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                />
              </div>
              <div
                v-if="submitted && !$v.form.password.required"
                class="text-sm text-red-600"
              >
                La contraseña es obligatoria
              </div>
            </div>
            <!-- password_confirmation -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label
                for="password_confirmation"
                class="block text-sm font-medium text-gray-700"
                >Confirma la contraseña</label
              >
              <div class="mt-1 w-full">
                <input
                  v-model="form.password_confirmation"
                  type="password"
                  name="password_confirmation"
                  id="password_confirmation"
                  :class="
                    submitted && $v.form.password_confirmation.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                />
              </div>
              <div
                v-if="submitted && !$v.form.password_confirmation.required"
                class="text-sm text-red-600"
              >
                La contraseña es obligatoria
              </div>
              <div
                v-if="
                  submitted && !$v.form.password_confirmation.sameAsPassword
                "
                class="text-sm text-red-600"
              >
                Las contraseñas no coinciden
              </div>
            </div>

            <!-- end of form inputs -->
          </div>
        </div>
        <div class="bg-gray-100 px-4 py-3 text-right sm:px-6  rounded-b-md">
          <button
            type="button"
            @click="close"
            class="inline-flex mr-2 justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          >
            Cerrar
          </button>
          <button
            type="submit"
            class="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            Crear
          </button>
        </div>
      </div>
    </form>
  </modal-container>
</template>

<script>
import moment from 'moment';
import ClickOutside from 'vue-click-outside';
import ModalContainer from '@/components/modals/ModalContainer.vue';
import AuthService from '@/services/authService';
import banks from '@/utils/bancos.json';
import countries from '@/utils/country-codes.json';
import regiones from '@/utils/comunas-regiones.json';
import { required, email, sameAs } from 'vuelidate/lib/validators';

export default {
  name: 'CreateUserModal',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {
    'modal-container': ModalContainer,
  },
  data() {
    return {
      regiones: regiones,
      banks: banks,
      types: ['Corriente', 'Vista'],
      countries: countries,
      countrySelector: false,
      country: {
        name: 'Chile',
        dialCode: '+56',
        isoCode: 'CL',
        flag: 'https://cdn.kcak11.com/CountryFlags/countries/cl.svg',
      },
      previsions: [
        'Fondo Nacional De Salud (Fonasa)',
        'Isalud Isapre De Codelco',
        'Isapre Banmédica',
        'Isapre Colmena',
        'Isapre Consalud',
        'Isapre Cruz Blanca',
        'Isapre Cruz Del Norte',
        'Isapre Fundación Banco Estado',
        'Isapre Nueva Másvida',
        'Isapre Vida Tres',
        'Particular',
        'Isapre Esencial',
      ],
      firstPress: true,
      form: {
        names: '',
        lastnames: '',
        email: '',
        email_confirmation: '',
        password: '',
        password_confirmation: '',
        bank: null,
        type: null,
        number: '',
        rut: '',
        phone: '',
        birthdate: null,
        day: '',
        month: '',
        year: '',
        region: '',
        comuna: '',
        address: '',
        prevision: null,
      },
      submitted: false,
    };
  },
  validations: {
    form: {
      names: {
        required,
      },
      lastnames: {
        required,
      },
      email: {
        required,
        email,
      },
      email_confirmation: {
        required,
        email,
        sameAsEmail: sameAs('email'),
      },
      password: {
        required,
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password'),
      },
      birthdate: {
        required,
      },
      region: {
        required,
      },
      comuna: {
        required,
      },
      address: {
        required,
      },
      prevision: {
        required,
      },
      rut: {
        required,
        rut: rut => {
          var Fn = {
            // Valida el rut con su cadena completa "XXXXXXXX-X"
            validaRut: function(rutCompleto) {
              if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
              var tmp = rutCompleto.split('-');
              var digv = tmp[1];
              var rut = tmp[0];
              if (digv == 'K') digv = 'k';
              return Fn.dv(rut) == digv;
            },
            dv: function(T) {
              var M = 0,
                S = 1;
              for (; T; T = Math.floor(T / 10))
                S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
              return S ? S - 1 : 'k';
            },
          };
          return Fn.validaRut(rut);
        },
      },
      phone: {
        required,
      },
    },
  },
  created() {
    moment.locale('es');
  },
  methods: {
    years_list() {
      const years = back => {
        const year = new Date().getFullYear();
        return Array.from(
          { length: back },
          (v, i) => year - back + i + 1
        ).reverse();
      };

      return years(100);
    },
    pressOutside() {
      if (!this.firstPress) {
        this.close();
      }
      if (this.firstPress) {
        this.firstPress = false;
      }
    },
    close() {
      this.$emit('then', {});
    },
    handleSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      AuthService.signPatient({
        names: this.form.names,
        lastnames: this.form.lastnames,
        email: this.form.email,
        email_confirmation: this.form.email_confirmation,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
        birthdate: this.form.birthdate,
        rut: this.form.rut,
        phone: this.form.phone,
        region: this.form.region,
        comuna: this.form.comuna,
        address: this.form.address,
        health: this.form.prevision,
      })
        .then(response => {
          this.$emit('then', response.data.user);
        })
        .catch(err => {
          console.log(`failed to create user: ${err}`);
        });
    },
  },
  watch: {
    'form.day'() {
      if (
        this.form.day != '' &&
        this.form.month != '' &&
        this.form.year != ''
      ) {
        this.form.birthdate =
          this.form.day + '/' + this.form.month + '/' + this.form.year;
      } else {
        this.form.birthdate = '';
      }
    },
    'form.month'() {
      this.form.day = '';
    },
    'form.year'() {
      this.form.month = '';
    },
  },
  computed: {
    comunas() {
      return this.regiones.find(e => e.region === this.form.region)
        ? this.regiones.find(e => e.region === this.form.region).comunas
        : [];
    },
    months() {
      moment.locale('es');
      if (this.form.year != '') {
        if (parseInt(this.form.year) == moment().year()) {
          let array = [];
          let selection = moment.monthsShort();
          for (let i = 0; i <= moment().month(); i++) {
            array.push(selection[i]);
          }
          return array;
        } else {
          return moment.monthsShort();
        }
      }
      return [];
    },
    days_list() {
      if (this.form.month != '' && this.form.year != '') {
        let dayInMonth = moment(
          this.form.year + '-' + this.form.month,
          'YYYY-MM'
        ).daysInMonth();
        let array = [];
        for (let i = 1; i <= dayInMonth; i++) {
          array.push(i);
        }
        return array;
      }
      return [];
    },
    age() {
      if (this.form.birthdate) {
        let date = moment(this.form.birthdate, 'DD/MM/YYYY');
        let years = moment().diff(date, 'years', false);
        let months = moment().diff(date, 'months', false);
        return (
          years +
          ' año' +
          (years != 1 ? 's' : '') +
          ' y ' +
          (months - 12 * years) +
          ' mes' +
          (months - 12 * years != 1 ? 'es' : '')
        );
      }
      return null;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
