<template>
  <div class="w-full">
    <tab-layout
      :sidebar="sidebar"
      :tabs="[
        { key: 'doctor', label: 'Doctores' },
        { key: 'psychologist', label: 'Psicólogos' },
        { key: 'patient', label: 'Pacientes' },
        { key: 'admins', label: 'Administradores' },
        { key: 'superdoctor', label: 'Super doctores' },
        { key: 'bonds', label: 'Prestaciones' },
        { key: 'management', label: 'Gestión' },
      ]"
      :contents="contents"
    />
  </div>
</template>

<script>
import Patients from '@/views/dashboard/user/admin/lists/Patients.vue';
import Doctors from '@/views/dashboard/user/admin/lists/Doctors.vue';
import Psychologist from '@/views/dashboard/user/admin/lists/Psychologists.vue';
import Admins from '@/views/dashboard/user/admin/lists/Admins.vue';
import Superdoctors from '@/views/dashboard/user/admin/lists/Superdoctors.vue';
import Bonds from '@/views/bonds/Bonds.vue';
import Management from '@/views/management/Management.vue';

import TabLayout from '@/components/dashboard/TabLayout.vue';
import UserCard from '@/components/dashboard/user/admin/selected/UserCard.vue';

export default {
  name: 'Tables',
  components: {
    'tab-layout': TabLayout,
  },
  data() {
    return {
      sidebar: { component: UserCard },
      contents: {
        doctor: { component: Doctors },
        psychologist: { component: Psychologist },
        patient: { component: Patients },
        admins: { component: Admins },
        superdoctor: { component: Superdoctors },
        bonds: { component: Bonds },
        management: { component: Management },
      },
    };
  },
};
</script>
