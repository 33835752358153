import authAxios from '@/utils/authAxios';

export default {
  getSuperdoctors: () => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(process.env.VUE_APP_BACKEND_URL + '/superdoctor')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
