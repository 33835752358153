<template>
  <div
    class="w-full py-8 mx-auto sm:px-6 lg:px-0 lg:grid lg:grid-cols-12 lg:gap-8"
  >
    <div class="col-span-1 lg:col-span-3">
      <nav aria-label="Sidebar" class="sticky top-24 divide-y divide-gray-300">
        <div class="pb-8 space-y-1">
          <button
            v-for="(tab, tabIndex) in tabs"
            :key="tabIndex"
            type="button"
            @click="
              () => {
                active = tab.key;
              }
            "
            :class="
              active === tab.key
                ? 'bg-gray-200 text-gray-900 group flex items-center px-3 py-2 text-sm font-semibold rounded-md w-full'
                : 'text-gray-600 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-semibold rounded-md w-full'
            "
          >
            <span class="truncate">
              {{ tab.label }}
            </span>
          </button>
          <component
            v-if="sidebar"
            :is="sidebar.component"
            v-bind="{ ...sidebar.props }"
          />
        </div>
      </nav>
    </div>
    <div class="col-span-1 md:col-span-9">
      <component
        v-if="active in contents"
        :is="contents[active].component"
        v-bind="{ ...contents[active].props }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableLayout',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    contents: {
      type: Object,
      default: () => {},
    },
    sidebar: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      active: '',
    };
  },
  created() {
    if (this.tabs.length !== 0) {
      this.active = this.tabs[0].key;
    }
  },
};
</script>
