var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('tab-layout',{attrs:{"sidebar":_vm.sidebar,"tabs":[
      { key: 'doctor', label: 'Doctores' },
      { key: 'psychologist', label: 'Psicólogos' },
      { key: 'patient', label: 'Pacientes' },
      { key: 'admins', label: 'Administradores' },
      { key: 'superdoctor', label: 'Super doctores' },
      { key: 'bonds', label: 'Prestaciones' },
      { key: 'management', label: 'Gestión' } ],"contents":_vm.contents}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }