var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('are-you-sure',{attrs:{"trigger":_vm.sureAdmin},on:{"toggle":function (event) {
        switch (event) {
          case 'save':
            _vm.setAdmin(_vm.sureAdmin);
            break;
          default:
            _vm.sureAdmin = null;
            _vm.finish('ok');
            break;
        }
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }