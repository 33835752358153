<template>
  <modal-container v-if="value">
    <form
      @submit.prevent="handleSubmit"
      v-click-outside="pressOutside"
      class="inline-block align-middle transform transition-all sm:max-w-2xl w-full"
    >
      <div class="overflow-hidden shadow-xl rounded-md">
        <div class="bg-white px-4 py-5 sm:p-6">
          <div class="grid grid-cols-6 gap-6">
            <!-- title -->
            <div class="col-span-6 flex flex-col justify-center items-start">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Crear un administrador
              </h3>
              <p class="mt-1 text-left max-w-2xl text-sm text-gray-500">
                Completa el formulario para crear una cuenta asociada a un
                administrador.
              </p>
            </div>
            <!-- names -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label for="names" class="block text-sm font-medium text-gray-700"
                >Nombres</label
              >
              <div class="mt-1 w-full">
                <input
                  v-model="form.names"
                  type="text"
                  name="names"
                  id="names"
                  :class="
                    submitted && $v.form.names.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="Escribe aquí"
                />
              </div>
              <div
                v-if="submitted && !$v.form.names.required"
                class="text-sm text-red-600"
              >
                Los nombres son obligatorios
              </div>
            </div>
            <!-- lastnames -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label
                for="lastnames"
                class="block text-sm font-medium text-gray-700"
                >Apellidos</label
              >
              <div class="mt-1 w-full">
                <input
                  v-model="form.lastnames"
                  type="text"
                  name="lastnames"
                  id="lastnames"
                  :class="
                    submitted && $v.form.lastnames.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="Escribe aquí"
                />
              </div>
              <div
                v-if="submitted && !$v.form.lastnames.required"
                class="text-sm text-red-600"
              >
                Los apellidos son obligatorios
              </div>
            </div>
            <!-- email -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label for="email" class="block text-sm font-medium text-gray-700"
                >Correo eletrónico</label
              >
              <div class="mt-1 w-full">
                <input
                  v-model="form.email"
                  type="text"
                  name="email"
                  id="email"
                  :class="
                    submitted && $v.form.email.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="xxxxx@xxxxx.xxx"
                />
              </div>
              <div
                v-if="submitted && !$v.form.email.required"
                class="text-sm text-red-600"
              >
                La dirección de correo es obligatoria
              </div>
              <div
                v-if="submitted && !$v.form.email.email"
                class="text-sm text-red-600"
              >
                La dirección de correo no es válida
              </div>
            </div>
            <!-- email_confirmation -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label
                for="email_confirmation"
                class="block text-sm font-medium text-gray-700"
                >Confirma el correo electrónico</label
              >
              <div class="mt-1 w-full">
                <input
                  v-model="form.email_confirmation"
                  type="text"
                  name="email_confirmation"
                  id="email_confirmation"
                  :class="
                    submitted && $v.form.email_confirmation.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                  placeholder="xxxxx@xxxxx.xxx"
                />
              </div>
              <div
                v-if="submitted && !$v.form.email_confirmation.required"
                class="text-sm text-red-600"
              >
                La dirección de correo es obligatoria
              </div>
              <div
                v-if="submitted && !$v.form.email_confirmation.email"
                class="text-sm text-red-600"
              >
                La dirección de correo no es válida
              </div>
              <div
                v-if="submitted && !$v.form.email_confirmation.sameAsEmail"
                class="text-sm text-red-600"
              >
                Las direcciones de correo no coinciden
              </div>
            </div>
            <!-- divider -->
            <div class="col-span-6 border-t border-gray-100 mt-2"></div>
            <!-- password -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label
                for="password"
                class="block text-sm font-medium text-gray-700"
                >Contraseña</label
              >
              <div class="mt-1 w-full">
                <input
                  v-model="form.password"
                  type="password"
                  name="password"
                  id="password"
                  :class="
                    submitted && $v.form.password.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                />
              </div>
              <div
                v-if="submitted && !$v.form.password.required"
                class="text-sm text-red-600"
              >
                La contraseña es obligatoria
              </div>
            </div>
            <!-- password_confirmation -->
            <div
              class="col-span-6 md:col-span-3 flex flex-col justify-center items-start"
            >
              <label
                for="password_confirmation"
                class="block text-sm font-medium text-gray-700"
                >Confirma la contraseña</label
              >
              <div class="mt-1 w-full">
                <input
                  v-model="form.password_confirmation"
                  type="password"
                  name="password_confirmation"
                  id="password_confirmation"
                  :class="
                    submitted && $v.form.password_confirmation.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
                  "
                  class="block border w-full rounded-md shadow-sm sm:text-sm"
                />
              </div>
              <div
                v-if="submitted && !$v.form.password_confirmation.required"
                class="text-sm text-red-600"
              >
                La contraseña es obligatoria
              </div>
              <div
                v-if="
                  submitted && !$v.form.password_confirmation.sameAsPassword
                "
                class="text-sm text-red-600"
              >
                Las contraseñas no coinciden
              </div>
            </div>

            <!-- end of form inputs -->
          </div>
        </div>
        <div class="bg-gray-100 px-4 py-3 text-right sm:px-6">
          <button
            type="button"
            @click="close"
            class="inline-flex mr-2 justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          >
            Cerrar
          </button>
          <button
            type="submit"
            class="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            Crear
          </button>
        </div>
      </div>
    </form>
  </modal-container>
</template>

<script>
import moment from 'moment';
import ClickOutside from 'vue-click-outside';
import ModalContainer from '@/components/modals/ModalContainer.vue';
import AuthService from '@/services/authService';

import { required, email, sameAs } from 'vuelidate/lib/validators';

export default {
  name: 'CreateUserModal',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {
    'modal-container': ModalContainer,
  },
  data() {
    return {
      firstPress: true,
      form: {
        names: '',
        lastnames: '',
        email: '',
        email_confirmation: '',
        password: '',
        password_confirmation: '',
      },
      submitted: false,
    };
  },
  validations: {
    form: {
      names: {
        required,
      },
      lastnames: {
        required,
      },
      email: {
        required,
        email,
      },
      email_confirmation: {
        required,
        email,
        sameAsEmail: sameAs('email'),
      },
      password: {
        required,
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },
  created() {
    moment.locale('es');
  },
  methods: {
    pressOutside() {
      if (!this.firstPress) {
        this.close();
      }
      if (this.firstPress) {
        this.firstPress = false;
      }
    },
    close() {
      this.$emit('then', null);
    },
    handleSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      AuthService.signAdmin({
        names: this.form.names,
        lastnames: this.form.lastnames,
        email: this.form.email,
        email_confirmation: this.form.email_confirmation,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
      })
        .then(response => {
          this.$emit('then', response.data.user);
        })
        .catch(err => {
          console.log(`failed to create user: ${err}`);
        });
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
