<template>
  <div class="w-full">
    <modal-register-patient
      :trigger="registerPatientToogle"
      @toggle="
        event => {
          registerPatientToogle = null;
          event == 'save' ? getDoctor() : '';
        }
      "
    />
    <modal-register-doctor
      :trigger="registerDoctorToogle"
      @toggle="
        event => {
          registerDoctorToogle = null;
          event == 'save' ? getDoctor() : '';
        }
      "
    />
    <modal-register-admin
      :trigger="registerAdminToogle"
      @toggle="
        event => {
          registerAdminToogle = null;
          event == 'save' ? getDoctor() : '';
        }
      "
    />
    <modal-delete-user
      :trigger="deleteToogle"
      @toggle="
        event => {
          deleteToogle = null;
          event == 'save' ? getDoctor() : '';
        }
      "
    />
    <modal-register-psychologist
      :trigger="registerPsychologistToogle"
      @toggle="
        event => {
          registerPsychologistToogle = null;
          event == 'save' ? getDoctor() : '';
        }
      "
    />
    <div class="mb-4 flex flex-row justify-end items-center w-full">
      <create-superdoctor @then="then" />
    </div>
    <input-search v-model="search" />
    <table-component
      :loading="loading"
      :head="[
        { key: 'doctor', label: 'Doctor' },
        { key: 'identity', label: 'Datos' },
        { key: 'bank', label: 'Banco' },
        { key: 'actions', label: 'Acciones' },
      ]"
      :body="body"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SuperdoctorService from '@/services/superdoctorService';
import RegisterDoctor from '@/views/dashboard/user/admin/modals/registerDoctor.vue';
import RegisterPatient from '@/views/dashboard/user/admin/modals/registerPatient.vue';
import RegisterAdmin from '@/views/dashboard/user/admin/modals/registerAdmin.vue';
import DeleteUser from '@/views/dashboard/user/admin/modals/deleteUser.vue';
import RegisterPsychologist from '@/views/dashboard/user/admin/modals/registerPsychologist.vue';
import CreateSuperdoctor from '@/views/dashboard/user/admin/create/superdoctor/Button.vue';

import InputSearch from '@/components/form/InputSearch.vue';
import Table from '@/components/dashboard/user/admin/Table.vue';

export default {
  name: 'Superdoctors',
  components: {
    'modal-register-patient': RegisterPatient,
    'modal-register-admin': RegisterAdmin,
    'modal-register-doctor': RegisterDoctor,
    'modal-delete-user': DeleteUser,
    'modal-register-psychologist': RegisterPsychologist,
    'create-superdoctor': CreateSuperdoctor,
    'input-search': InputSearch,
    'table-component': Table,
  },
  data() {
    return {
      search: '',
      doctors: [],
      registerDoctorToogle: null,
      registerPatientToogle: null,
      registerAdminToogle: null,
      deleteToogle: null,
      registerPsychologistToogle: null,
      loading: true,
    };
  },
  created() {
    this.getDoctor();
  },
  methods: {
    ...mapActions('admin', ['changeUser']),
    then(doctor) {
      if (doctor) {
        this.getDoctor();
      }
    },
    getDoctor() {
      SuperdoctorService.getSuperdoctors()
        .then(response => {
          this.doctors = response.data.superdoctors;
          this.loading = false;
        })
        .catch(() => {
          console.log('error getting doctors');
        });
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
    body() {
      let temp = this.doctors;
      if (this.search != '') {
        temp = temp.filter(e => {
          let name_temp = e.names + ' ' + e.lastnames;
          return name_temp.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      temp = temp.sort(function(a, b) {
        if (a.id > b.id) {
          return -1;
        }
        if (a.id < b.id) {
          return 1;
        }
        return 0;
      });
      return temp.map(e => {
        return {
          doctor: [
            { label: e.names + ' ' + e.lastnames, class: 'capitalize' },
            { label: e.email, class: 'text-gray-600 lowercase' },
            {
              label: e.superdoctor.specialty,
              class:
                'w-auto text-green-900 bg-green-100 rounded-md px-2 mt-1 lowercase',
            },
          ],
          identity: [
            { label: e.superdoctor.phone },
            { label: e.superdoctor.rut },
          ],
          bank: [
            { label: e.superdoctor.bank, class: 'capitalize' },
            { label: e.superdoctor.type, class: 'text-gray-600 capitalize' },
            { label: e.superdoctor.number, class: 'text-gray-600' },
          ],
          actions: [
            {
              label: 'convertir admin',
              dropdown: true,
              class:
                'bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-md my-1 w-full',
              action: () => {
                this.registerAdminToogle = e.id;
              },
            },
            {
              label: 'convertir a doctor',
              dropdown: true,
              class:
                'bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-md my-1 w-full',
              action: () => {
                this.registerDoctorToogle = e.id;
              },
            },
            {
              label: 'convertir a paciente',
              dropdown: true,
              class:
                'bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-md my-1 w-full',
              action: () => {
                this.registerPatientToogle = e.id;
              },
            },
            {
              label: 'convertir a psicólogo',
              dropdown: true,
              class:
                'bg-gray-100 hover:bg-gray-200 px-2 py-1 rounded-md my-1 w-full',
              action: () => {
                this.registerPsychologistToogle = e.id;
              },
            },
            {
              label: 'eliminar',
              dropdown: true,
              class:
                'bg-red-100 hover:bg-red-200 px-2 py-1 rounded-md my-1 w-full',
              action: () => {
                this.deleteToogle = e.id;
              },
            },
            {
              label: 'seleccionar',
              class:
                'bg-gray-700 hover:bg-gray-800 text-gray-100 px-2 py-1 rounded-md mb-2 w-full',
              action: () => {
                this.changeUser(e);
              },
            },
          ],
        };
      });
    },
  },
};
</script>
