<template>
  <div class="w-full grid gap-x-2 grid-cols-12">
    <select
      v-model="day"
      class="col-span-3 w-full disabled:text-gray-400 border border-gray-200 bg-white rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
    >
      <option
        v-for="(dayElement, dayIndex) in days"
        :key="dayIndex"
        :value="dayElement"
        >{{ dayElement }}</option
      >
    </select>
    <select
      v-model="month"
      class="col-span-5 w-full disabled:text-gray-400 border border-gray-200 bg-white rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
    >
      <option
        v-for="(monthElement, monthIndex) in months()"
        :key="monthIndex"
        :value="monthIndex"
        >{{ monthElement }}</option
      >
    </select>
    <select
      v-model="year"
      class="col-span-4 w-full disabled:text-gray-400 border border-gray-200 bg-white rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
    >
      <option
        v-for="(yearElement, yearIndex) in years()"
        :key="yearIndex"
        :value="yearElement"
        >{{ yearElement }}</option
      >
    </select>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    default: {
      type: String,
      default: `${moment().date()}/${moment().month()}/${moment().year()}`,
    },
  },
  data() {
    return {
      value: this.default,
      day: parseInt(this.default.split('/')[0]),
      month: parseInt(this.default.split('/')[1]),
      year: parseInt(this.default.split('/')[2]),
    };
  },
  methods: {
    months() {
      moment.locale('es');
      return moment.months();
    },
    years() {
      const years = back => {
        const year = new Date().getFullYear();
        return Array.from(
          { length: back },
          (v, i) => year - parseInt(back / 2) + i + 1
        );
      };

      return years(10);
    },
  },
  watch: {
    value() {
      this.$emit('update', this.value);
    },
    day() {
      this.value = `${this.day}/${this.month}/${this.year}`;
    },
    month() {
      this.day = this.day > this.days ? 1 : this.day;
      this.value = `${this.day}/${this.month}/${this.year}`;
    },
    year() {
      this.day = this.day > this.days ? 1 : this.day;
      this.value = `${this.day}/${this.month}/${this.year}`;
    },
  },
  computed: {
    days() {
      return moment(
        `${parseInt(this.month) + 1}/${this.year}`,
        'MM/YYYY'
      ).daysInMonth();
    },
  },
};
</script>
